import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {StyledEngineProvider} from '@mui/material/styles';

const originalConsoleLog = console.log;

console.log = function (...args) {

    if (args.length > 0 && typeof args[0] === 'string' && args[0].includes('TraceablePeerConnection')) {
        return; // חוסם לוגים שמכילים את המחרוזת "TraceablePeerConnection"
    }

    originalConsoleLog(...args);
};
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <StyledEngineProvider injectFirst>
        <App />
    </StyledEngineProvider>

    // </React.StrictMode>
);
/*
let root = document.getElementById('root');
let n = document.createElement('div');

if(root){
    root.appendChild(PlayroomNative())
    root.appendChild(n)
}

const rootR = ReactDOM.createRoot(
    n as HTMLElement
);
rootR.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)*/



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
