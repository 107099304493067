import SvgIcon from '@mui/material/SvgIcon';
import React from 'react'

const AddCardIcon = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" fill="none" style={{transform: "scale(1.2)"}}>
            <path d="M15.9225 4.79883H17.8856C18.6083 4.79883 19.1942 5.38474 19.1942 6.10751V20.503C19.1942 21.2258 18.6083 21.8117 17.8856 21.8117H9.59726C8.87449 21.8117 8.28857 21.2258 8.28857 20.503V19.1943" stroke="white" strokeWidth="1.3" fill="none" />
            <rect x="6.10713" y="3.05341" width="10.0332" height="16.1404" rx="0.872454" stroke="white" strokeWidth="1.3" fill="none" />
            <path d="M10.6608 14.8317V11.7762H7.85205V10.9076H10.6608V7.85205H11.5867V10.9076H14.3955V11.7762H11.5867V14.8317H10.6608Z" strokeWidth="0.5" stroke="white" />
        </SvgIcon>
    );
}

export default React.memo(AddCardIcon);