import React, { useEffect, useRef, useState } from "react";
import {VIDEO_DOMAIN} from "../constants";
import IconButton from '@mui/material/IconButton';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import "../../scss/Jitsi.scss"
import {CUSTOM_JITSI_CSS} from "./assets/JitsiStyles";
import {isRTL} from "../GlobalUtils";


let api = null;
const Jitsi = ({ roomName, displayName, onHangup, isFS }) => {

    const jitsiContainerRef = useRef(null);
    const [jitsiLoaded, setJitsiLoaded] = useState(false);
    const [state, setState] = useState("mini");
    const [isMicMuted, setIsMicMuted] = useState(true);
    const [isVideoMuted, setIsVideoMuted] = useState(false);


    const toggleVideo = e => {
        api?.isVideoMuted().then((isMuted) => {
            if (isMuted) {
                console.log("Unmute");
                //api.executeCommand('mute', false); // מבטל השתקה רק אם מושתק
            } else {
                console.log("mute")
                //api.executeCommand('mute', true); // מבטל השתקה רק אם מושתק
            }
            api.executeCommand('toggleVideo')
            setIsVideoMuted(!isVideoMuted);
        });
    }
    const toggleMute = e => {
        api?.isAudioMuted().then((isMuted) => {
            if (isMuted) {
                console.log("Unmute");
                //api.executeCommand('mute', false); // מבטל השתקה רק אם מושתק
            } else {
                console.log("mute")
                //api.executeCommand('mute', true); // מבטל השתקה רק אם מושתק
            }
            api.executeCommand('toggleAudio')
            setIsMicMuted(!isMuted);
        });

        //api?.executeCommand('toggleAudio'); // מפעיל או משתיק את המיקרופון
    }
    const toggleState = e => {
        setState(state === "mini" ? "big" : "mini")
    }
    useEffect(() => {
        if(isFS){
            setState("fs");
        } else {
            setState("mini");
        }
    }, [isFS])

    useEffect(() => {
        // TODO Bugfix: displayName is always set to undefined. Jitsy should appear only after playroom initialization
        if (!roomName || !displayName) {
            // לא נטען אם אחד הפרמטרים חסר
            console.log("Waiting for roomName and displayName to be defined.");
            return;
        }
        if(jitsiLoaded){
            api?.executeCommand('displayName', displayName)
            console.log("Jitsi already loaded");
            return;
        }
        setJitsiLoaded(true);

        // Function to load the Jitsi external_api.js script dynamically
        const loadJitsiScript = () => {
            return new Promise((resolve, reject) => {
                if (window.JitsiMeetExternalAPI) {
                    resolve(); // If the script is already loaded, resolve immediately
                    return;
                }

                const script = document.createElement("script");
                script.src = `https://${VIDEO_DOMAIN}/external_api.js`; // Replace with your server if needed
                script.async = true;

                script.onload = () => {
                    console.log("Jitsi Meet script loaded!");
                    resolve();
                };

                script.onerror = () => {
                    console.error("Failed to load Jitsi Meet script");
                    reject(new Error("Failed to load Jitsi Meet script"));
                };

                document.body.appendChild(script);
            });
        };

        const fixParticipantStyles = () => {
            const videoContainers = document.querySelectorAll('span.display-video.video-container');

            videoContainers.forEach(container => {
                container.style.position = 'static'; // שנה מ-absolute ל-static
                container.style.flex = '0 0 auto'; // ודא שהם נשארים בתוך Flexbox
            });
        }
        // Initialize Jitsi Meet after the script is loaded
        const initJitsi = async () => {
            try {
                await loadJitsiScript();

                const domain = VIDEO_DOMAIN; // Replace with your server domain if using a custom server
                const options = {
                    roomName: roomName,
                    width: "100%",
                    height: "100%",
                    parentNode: jitsiContainerRef.current,
                    userInfo: {
                        displayName: displayName,
                    },
                    configOverwrite: {
                        startWithVideoMuted: false, // לא לכבות מצלמה
                        startWithAudioMuted: true,
                        disableDeepLinking: true,
                        disableLayerSuspension: true, // מניעת השבתת וידאו
                        disableSimulcast: true,
                        prejoinPageEnabled: false, // חיבור ישיר ללא מסך בחירה
                        disableModeratorIndicator: true, // לא מציג הודעות מודרטור
                        disableNotifications: true, // מסיר כל התראות
                        subject: '', // הסרת שם החדר
                        hideConferenceSubject: true, // מסתיר את שם החדר
                        logging: {
                            defaultLogLevel: 'error', // מצמצם לוגים רק לשגיאות
                            disableLogCollector: true, // חוסם איסוף לוגים
                        }
                    },
                    interfaceConfigOverwrite: {
                        filmStripOnly: false,
                        SHOW_JITSI_WATERMARK: false,
                        //VERTICAL_FILMSTRIP: false,
                        //HORIZONTAL_FILMSTRIP: true,
                        FILM_STRIP_MAX_HEIGHT: 100, // מסיר את הפילם סטריפ
                        TILE_VIEW_MAX_COLUMNS: 5, // מצמצם את העמודות ליחידת וידאו אחת
                        SHOW_BRAND_WATERMARK: false,
                        HIDE_CONFERENCE_SUBJECT: true,
                        DISABLE_TRANSCRIPTION_SUBTITLES: true,
                        //DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
                        SHOW_WATERMARK_FOR_GUESTS: false,
                        SHOW_CHROME_EXTENSION_BANNER: false,
                        TOOLBAR_BUTTONS: [], // הסרת כל כפתורי ה-UI
                        SHOW_TIMER: false, // מסיר את הטיימר

                    },
                };

                api = new window.JitsiMeetExternalAPI(domain, options);

                //api.executeCommand('displayName', ''); // הסרת שמות
                api.executeCommand('subject', ''); // הסרת נושא החדר


                api.addEventListener('remoteTrackAdded', (track) => {
                    console.log("ADDED TRACK")
                    if (track.type === 'video') {
                        console.log("VIDEO")
                        //const videoElement = track.attach(); // צור אלמנט וידאו
                        //document.querySelector('#custom-grid').appendChild(videoElement); // מקם אותו בגריד מותאם
                    }
                });

                // Example of adding event listeners
                api.addEventListener("participantLeft", (participant) => {
                    console.log("Participant left:", participant.displayName);
                    //fixParticipantStyles();
                });

                api.addEventListener("participantJoined", (participant) => {
                    console.log("Participant joined:", participant.displayName);
                    //fixParticipantStyles();
                });

                api.addEventListener("readyToClose", () => {
                    if (onHangup) onHangup();
                });

                api.addEventListener('audioMuteStatusChanged', (event) => {
                    console.log(`Audio mute status: ${event.muted ? 'Muted' : 'Unmuted'}`);
                    setIsMicMuted(event.muted);
                });
                api.addEventListener('videoMuteStatusChanged', (event) => {
                    console.log(`Video mute status: ${event.muted ? 'Muted' : 'Unmuted'}`);
                    setIsVideoMuted(event.muted);
                });

                api.addEventListener('participantRoleChanged', (event) => {
                    if (event.role === 'moderator') {
                        console.log('Moderator notification suppressed');
                    }
                });
                api.addEventListener('videoConferenceJoined', () => {
                    api.executeCommand('subject', ''); // הסתרת נושא
                    api.executeCommand('toggleTileView');   // הפעלת Tile View

                    //fixParticipantStyles();

                });
                api.addEventListener('dominantSpeakerChanged', (event) => {

                    if (!event.id) {
                        console.log("No dominant speaker");
                        return;
                    }
                    const dominantSpeakerId = event.id; // מזהה המשתתף שדיבר לאחרונה
                    console.log("Dominant speaker ID:", dominantSpeakerId);

                    // הצגת המשתתף בלבד
                    //showParticipant(dominantSpeakerId);
                });
                function showParticipant(participantId) {
                    const participants = api.getParticipantsInfo();
                    const participant = participants.find(p => p.participantId === participantId);

                    if (participant) {
                        console.log("Current speaker:", participant.displayName || "Anonymous");
                    }

                    api.executeCommand('setLargeVideoParticipant', participantId);
                    api.pinParticipant(participantId); // ממקד במשתתף
                }
                function getParticipants() {
                    const participants = api.getParticipantsInfo();
                    console.log("Connected participants:", participants);
                }

                api.isAudioMuted().then((isMuted) => {
                    setIsMicMuted(isMuted);
                });
                api.isVideoMuted().then((isMuted) => {
                    setIsVideoMuted(isMuted);
                });

                const iframe = document.querySelector('iframe'); // בחר את ה-iframe שבו רץ Jitsi
                iframe.addEventListener("load", () => {
                    console.log("LOADED!!!!!")
                    iframe.contentWindow.postMessage({
                        type: "set-dir",
                        dir: isRTL() ? "rtl" : "ltr"
                    }, `https://${VIDEO_DOMAIN}`)
                    console.log("rtl: " + isRTL())
                    iframe.contentWindow.postMessage({
                        type: "inject-css",
                        styles: CUSTOM_JITSI_CSS
                    }, `https://${VIDEO_DOMAIN}`);

                })



                // Cleanup on component unmount
                return () => {
                    if (api) {
                        api.dispose();
                    }
                };
            } catch (error) {
                console.error("Error initializing Jitsi Meet:", error);
            }
        };

        // TODO fetch this URL https://video.vicapro.com/robots.txt and disable video in not available. Display a clear "video unavailable" message as well
        initJitsi();

        // Cleanup on component unmount
        return () => {
            if (jitsiContainerRef.current) {
                jitsiContainerRef.current.innerHTML = ""; // Clear the container
            }
        };
    }, [roomName, displayName]);

    if (!roomName || !displayName) {
        return <p>Loading Jitsi... Please wait for room and display name.</p>;
    }

    return (
        <div className={`video_wrapper ${state}`}>
            <div className="video_meet" ref={jitsiContainerRef} />
            {/*{state !== "fs" &&
                <IconButton style={{minHeight: 0, padding: "2px", position: "absolute", top: 0, right: 0, zIndex: 11, filter: "drop-shadow(0 0 2px black)"}} onClick={toggleState}>
                    {state === "mini" && <OpenInFullIcon />}
                    {state === "big" && <CloseFullscreenIcon />}
                </IconButton>
            }*/}
            {/*<div className="video_bar">
                <IconButton style={{minHeight: 0, height: "100%"}} onClick={toggleMute}>
                    {isMicMuted ? <MicOffIcon /> : <MicIcon />}
                </IconButton>

                {state === "fs" &&
                    <IconButton style={{minHeight: 0, height: "100%"}} onClick={toggleVideo}>
                        {isVideoMuted ? <VideocamOffIcon /> : <VideocamIcon />}
                    </IconButton>
                }
            </div>*/}

        </div>
    )
};

export default React.memo(Jitsi);

