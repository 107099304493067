export const CUSTOM_JITSI_CSS = `
    body {
        background: none;
    }
   .tile-view .remote-videos>div {
        height: 100% !important;
        width: 100% !important;
        justify-content: normal;
   }
  .tile-view #remoteVideos {
        justify-content: normal;
  }
   .tile-view .filmstrip__videos .videocontainer, .tile-view .filmstrip__videos .videocontainer {
        height: 100% !important;
        min-height: 0 !important;
        pointer-events: none;
       
   }
   body.rtl .tile-view .filmstrip__videos .videocontainer, body.rtl .tile-view .filmstrip__videos .videocontainer {
        direction: rtl;
   }
   
    #largeVideoWrapper {
      position: absolute;
      top: 0;
      left: 20%;
      width: 80%;
      height: 100%;
    }
    #largeVideoContainer {
        background: none !important;
    }
    #localVideoWrapper {
        box-shadow: none !important;
        /*width: auto;*/
    }
    .filmstrip__videos {
        width: 100% !important;
    }
    #filmstripLocalVideo {
        display: none;
    }
    span.videocontainer {
        position: relative !important;
        left: auto !important;
        top: auto !important;
        width: auto !important;
        min-width: auto !important;
        aspect-ratio: 4 / 3;
    }
    body.rtl .css-198b1hj-withI18nextTranslation_Mae_-indicatorsBackground > :not(:last-child) {
        margin-right: 0;
        margin-left: 8px;
    }
    .css-14q7u5l-withI18nextTranslation_Mae_-activeSpeaker .active-speaker-indicator {
        box-shadow: 0px 0px 0px 3px #8AE6E8 inset !important;
    }
    
`