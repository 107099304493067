import React, {useEffect, useRef, useState} from 'react';
import CardMaker from "./js/components/CardMaker";
import {OFFLINE_PLAYROOM} from "./js/constants";
import PlayroomNative from "./js/components/playroom/PlayroomNative";
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import backendService from "./js/BackendService"
import {useCookies} from 'react-cookie';
import './js/i18n';
import "./css/App.css"
import WelcomeScreen from "./js/components/WelcomeScreen";
import ShareScreen from "./js/components/ShareScreen";
import {useTranslation} from "react-i18next";
import {getPartyCookieName, withParams} from "./js/GlobalUtils";
import NewPlayroomScreen from "./js/components/NewPlayroomScreen";
import GlobalLoader, {useLoader} from "./js/components/GlobalLoader";
import ProtectedRoute from "./js/components/ProtectedRoute";
import OwnerMenuBar from "./js/components/OwnerMenuBar";
import SessionInfo from "./js/components/SessionInfo";
import {Beforeunload} from 'react-beforeunload';
import Drawer from '@mui/material/Drawer';

import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';

import Split from 'react-split';


import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import {DialogsProvider} from '@toolpad/core/useDialogs';
import SettingsMenu from "./js/components/SettingsMenu";
import Jitsi from "./js/components/Jitsi";
import AddCardSpeedDial from "./js/components/AddCardSpeedDial";
import {LOGOUT} from "./js/recommendedActions";
import VicaSplit from "./js/components/VicaSplit";


function App() {


    return (
        <Router>
            <GlobalLoader>
                <AppContent />
            </GlobalLoader>
        </Router>
    );
}

export const AppContext = React.createContext(null);
let backendServiceListenersAttached = false;

const NotFound = () => <div>Page not found</div>;

function AppContent() {

    const location = useLocation();
    const navigate = withParams(useNavigate(), location);
    const { showLoader, hideLoader } = useLoader();

    const [uiState, setUIState] = useState("playroom");
    const [isVideoFS, setVideoFs] = useState(false);

    const playroomRef = useRef();
    let playroomId = location.pathname.split("/")[1]
    const [isOwner, setIsOwner] = useState(false);
    const [cardMakerAvailable, setCardMakerAvailable] = useState(true);
    const [allowMultipleUploads, setAllowMultipleUploads] = useState(false);

    const [playroomOfflineInfo, setPlayroomOfflineInfo] = useState({});

    const [connected, setConnected] = useState(true);

    const [isViewOnly, setIsViewOnly] = useState(false);

    const { t, i18n } = useTranslation();

    const [cookies, setCookie, removeCookie] = useCookies(['token', "userData"]);
    const [displayName, setDisplayName] = useState("");

    const [isVideoFlagEnabled] = useState(new URLSearchParams(location.search).get("v") === "1");
    const [isVideoEnabled, setIsVideoEnabled] = useState(false);

    const params = new URLSearchParams(location.search);
    const [lang, setLang] = useState(params.get("lang") || "he");
    const [deckId, setDeckId] = useState(params.get("deckId") || "")

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [menuVisible, setMenuVisible] = useState(false);
    const [topBarVisible, setTopBarVisible] = useState(true);

    const [sessionConfig, setSessionConfig] = useState(null);
    const [cardMakerState, setCardMakerState] = useState("camera");

    const [focusSection, setFocusSection] = useState(-1);


    let playroomInitialized = false;

    //useBeforeunload(e => e.preventDefault());


    const hasActiveHw = (playroomInfo = playroomOfflineInfo) => {
        return playroomInfo?.hwExpiration !== undefined && playroomInfo?.hwExpiration > new Date().getTime();
    }

    const initPlayroom = async () => {
        if(!playroomId) return;

        let partyId = "";
        if(playroomId == OFFLINE_PLAYROOM){
            partyId = "offline";        // TODO TEMP
        } else {
            setConnected(false);
        }

        if(!backendServiceListenersAttached){
            backendService.on("go_offline", data => {
                // TODO display alert only if not owner
                console.log("ENDED - GOING OFFLINE");
                setIsViewOnly(true);
                //setAlertVisible(true);

                //setAlertMessage( t("playroom_offline_message"))
            });
            backendService.on("playroom_state", data => {
                setIsOwner(data.isOwner);
                playroomInitialized = true;

                let config = backendService.getSessionConfig();
                setSessionConfig(config);
                setCardMakerAvailable(config.partyPermissions.addCards);
                setAllowMultipleUploads(config?.partyPermissions?.uploadMultiple);

            })
            backendService.on("session_updated", config => {
                hideLoader();
                setSessionConfig(config);
                setCardMakerAvailable(config.partyPermissions.addCards);
                setAllowMultipleUploads(config?.partyPermissions?.uploadMultiple);

            });
            backendService.on("connected", () => {
                setConnected(true);
            })
            backendService.on("disconnected", () => {
                if(playroomInitialized){
                    setConnected(false);
                }

            })
            backendService.on("message", data => {
                if(data.recommendedAction){
                    switch (data.recommendedAction){
                        case LOGOUT:
                            removeCookie("token");
                            removeCookie("userData");
                            navigate(`/`);
                            break;
                    }
                    alert(data.message);
                } else {
                    switch(data.type){
                        case "error":
                            switch(data.status){
                                case 404:
                                    setAlertMessage(data.message)
                                    setAlertVisible(true);
                                    break;
                                case 401:
                                    const partyCookie = cookies[getPartyCookieName(playroomId)];
                                    if(partyCookie){
                                        partyId = partyCookie.partyId;
                                        console.log(partyId);
                                    }
                                    if(partyId){
                                        setDisplayName(partyCookie.fullName);
                                        backendService.init(playroomId, partyId, "", partyCookie.fullName, playroomId === OFFLINE_PLAYROOM, lang);
                                    } else {
                                        navigate(`/${playroomId}/welcome`);
                                    }
                                    break;
                            }
                            break;
                    }
                }
            })

            backendServiceListenersAttached = true;
        }

        if(cookies.userData){
            console.log("setting display name to " + `${cookies.userData.firstName} ${cookies.userData.lastName}`);

            // TODO this should be normalized! make sure only camelCase fields are returned
            if(cookies.userData.firstName){
                setDisplayName(`${cookies.userData.firstName} ${cookies.userData.lastName}`);
            } else if(cookies.userData.first_name){
                setDisplayName(`${cookies.userData.first_name} ${cookies.userData.last_name}`);
            }

        }

        if(!cookies.token){     // TODO ask user how to connect: owner / party
            navigate(`/${playroomId}/welcome`);
        }
        if(playroomId && playroomId !== OFFLINE_PLAYROOM){
            try{
                let playroomInfo = await backendService.getOfflineDetails(playroomId);
                console.log(playroomInfo)
                setPlayroomOfflineInfo(playroomInfo);

            } catch (err){
                alert(err.message);
            }
        }

        // Actually there shouldn't be any partyId here, this is set at WelcomeScreen
        if(partyId || cookies.token){
            // TODO check for admins user for offline activities!!!
            backendService.init(playroomId, partyId, cookies.token, "", playroomId === OFFLINE_PLAYROOM, lang);
        }
    }

    useEffect(() => {
        i18n.changeLanguage(lang)
    }, [lang])

    useEffect(() => {
        if(playroomId !== "offline") return;
        // TODO getDeck from server and load cards
        if(!deckId) return;
        /*for(let i = 0; i < 60; i++){
            playroomRef.current.addCard(`https://content.vicapro.com/decks/${deckId}/web/${i}.jpg`, generateFirestoreId())
        }*/
    }, [deckId])

    useEffect(() => {
        const originalAlert = window.alert;
        window.alert = (message) => {
            setAlertMessage(message);
            setAlertVisible(true);
        }
        initPlayroom();
        return () => {
            window.alert = originalAlert;
        }
    }, [])

    const onEnterPlayroom = (partyId, fullName) => {
        setDisplayName(fullName);
        backendService.init(playroomId, partyId, "", fullName, playroomId === OFFLINE_PLAYROOM, lang);
        navigate(`/${playroomId}`);
    }


    useEffect(() => {
        const currentLanguage = i18n.language;
        document.body.classList.toggle('rtl', currentLanguage === 'he');
        document.body.classList.toggle('ltr', currentLanguage !== 'he');
    }, [i18n.language]);


    useEffect(() => {
        setUIState((location.pathname === `/${playroomId}/cardMaker` || location.pathname === `/${playroomId}/cardMaker/camera`) ? "card_maker" : "playroom")
        if(location.pathname === `/${playroomId}/video`){
            setVideoFs(true)
            setFocusSection(1);
        } else {
            setVideoFs(false)
            setFocusSection(-1)
        }
    }, [location])

    useEffect(() => {
        console.log(uiState);
    }, [uiState])

    useEffect(() => {
        console.log("IS VIEW ONLY");
        console.log(isViewOnly);
        setTopBarVisible(isOwner || isViewOnly || hasActiveHw() || isVideoFlagEnabled);
    }, [isVideoFlagEnabled, isViewOnly, playroomOfflineInfo, isOwner])

    useEffect(() => {
        let enabled = !isViewOnly && isVideoFlagEnabled && sessionConfig && (!sessionConfig.ended || sessionConfig.ended > new Date().getTime());
        setIsVideoEnabled(enabled)
        if(enabled){

        }
    }, [isViewOnly, isVideoFlagEnabled, sessionConfig])


    const onPopupClose = () => {
        navigate(`/${playroomId}`);  // Navigate back to Playroom
    }

    const onChangeArrangement = type => {
        showLoader(t("switching_activity"));    // TODO apply immediately in local client
        hideMenu();
        backendService.newSession({arrangement: type, reuseDeck: true});
    }
    const hideMenu = () => {
        setMenuVisible(false);
    }
    const showMenu = () => {
        setMenuVisible(true);
    }
    const showCardMaker = () => {
        navigate(`/${playroomId}/cardMaker/camera`);  // Navigate to CardMaker
    }
    const showCameraWidget = () => {
        //setCardMakerState("camera");
        navigate(`/${playroomId}/cardMaker/camera`);  // Navigate to CardMaker
    }
    const showUploadWidget = () => {
        //setCardMakerState("upload");
        navigate(`/${playroomId}/cardMaker/camera`);  // Navigate to CardMaker
    }
    const showTextWidget = () => {
        //setCardMakerState("text");
        navigate(`/${playroomId}/cardMaker/camera`);  // Navigate to CardMaker
    }

    const onCardCreated = (url, blob) => {

        navigate(`/${playroomId}`);  // Navigate back to Playroom
        let generatedId = backendService.addCard(blob, {text: "Hi there"})


        if(playroomRef.current){
            playroomRef.current.addCard(url, url, generatedId);   // Add some appearance effect to the added card
        }
    }

    const onPlayroomCreated = prId => {
        playroomId = prId;
        initPlayroom();
        navigate(`/${playroomId}`, {replace: true})
    }

    const onTest = () => {
        showLoader("מאתחלים פעילות חדשה");
        backendService.newSession({arrangement: "gallery", reuseDeck: true});
        //playroomRef.current.onTest();
    }

    const onBeforeUnload = e => {
        if(connected || isViewOnly) return;
        e.preventDefault()
    }

    const addCardActions = [
        { icon: <CollectionsOutlinedIcon />, name: t("add_card_upload"), color: '#2A6DF0', action: showUploadWidget },
        { icon: <PhotoCameraOutlinedIcon />, name: t("add_card_camera"), color: '#C74D90', action: showCameraWidget },
        { icon: <EditNoteIcon />, name: t("add_card_text"), color: '#5CC5C7', action: showTextWidget },
    ];


    const closeAction = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setAlertVisible(false)}
                sx={{flex: "1 0 auto", justifyContent: "end"}}
                style={{flex: "1 0 auto", justifyContent: "end"}}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );
    const testHandler = e => {
        console.log(e.target);
    }

    return (
        <DialogsProvider className={"dir"}>
            <Beforeunload onBeforeunload={onBeforeUnload} />
            <AppContext.Provider value={{isOwner: isOwner, playroomId: playroomId}}>
            <div className={`App${isVideoEnabled ? " vid" : ""}`}>
                <div style={{display: "flex", flexDirection: "column", position: "absolute", height: "100%", width: "100%", overflow: "hidden"}}>
                    <Routes>
                        <Route path="/:playroomId/*" element={
                            topBarVisible && <OwnerMenuBar hwInfo={hasActiveHw() && playroomOfflineInfo} isViewOnly={isViewOnly} isOwner={isOwner} playroomId={playroomId} videoEnabled={isVideoFlagEnabled} onMenu={showMenu} onTest={onTest} />
                        } />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <div style={{flex: "1", flexDirection: "column", position: "relative", overflow: "hidden"}}>
                        <VicaSplit
                               initialSizes={[80,20]}
                               gutterSize={10}
                               direction="vertical"
                               cursor="row-resize"
                               minSize={[0, 0]}
                               className="blah"
                               focusSection={focusSection}
                               style={{position: "absolute", top: 0, bottom: 0, width: "100%"}}>
                            <div className="playroom_container minimizable">
                                <PlayroomNative ref={playroomRef} />
                                <Routes>
                                    <Route path="/:playroomId/" element={
                                        cardMakerAvailable && !isViewOnly &&
                                        <AddCardSpeedDial actions={addCardActions} />
                                    } />
                                    <Route path="/:playroomId/monitor/*" element={
                                        <ProtectedRoute>
                                            <SessionInfo playroomId={playroomId} />
                                        </ProtectedRoute>
                                    } />
                                    <Route path="*" element={<NotFound />} />

                                </Routes>
                            </div>
                            <div className="video_container minimizable">
                                {
                                    isVideoEnabled &&
                                    <Jitsi roomName={playroomId} displayName={displayName} isFS={isVideoFS}/>
                                }
                            </div>

                        </VicaSplit>


                        <Routes>


                            <Route path="*" element={<NotFound />} />

                        </Routes>
                    </div>
                </div>

                <Routes>


                    {!isOwner && !isViewOnly &&
                        <Route path="/:playroomId/welcome" element={
                            <WelcomeScreen playroomId={playroomId} onEnter={onEnterPlayroom} offlineInfo={playroomOfflineInfo}/>
                        } />
                    }


                    <Route path="/:playroomId/share" element={
                        <ProtectedRoute>
                            <ShareScreen playroomId={playroomId} />
                        </ProtectedRoute>
                    } />

                    <Route path="/" element={
                        <NewPlayroomScreen onPlayroomCreated={onPlayroomCreated}/>
                    } />
                    {!isViewOnly &&
                        <Route path="/:playroomId/cardMaker/*" element={
                            <>
                                <div className={"bg_cover"} />
                                <CardMaker widgetState={cardMakerState} allowMultiple={isOwner || allowMultipleUploads} onClose={onPopupClose} onCardCreated={onCardCreated}></CardMaker>
                            </>
                        } />
                    }

                    <Route path="*" element={<NotFound />} />


                </Routes>


            </div>
            </AppContext.Provider>
            <Snackbar
                open={alertVisible}
                className="dir"
                autoHideDuration={4000}
                TransitionComponent={Slide}
                onClose={e => setAlertVisible(false)}
                message={alertMessage}
                action={closeAction}
            />
            <Drawer open={menuVisible} onClose={hideMenu} >
                <SettingsMenu config={sessionConfig} onChangeArrangement={onChangeArrangement}/>
            </Drawer>
        </DialogsProvider>
    );
}

export default App;
