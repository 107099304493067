import AddCardIcon from "./icons/AddCardIcon";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {useState, useEffect, useRef} from "react"

import "../../scss/AddCardSpeedDial.scss"


const AddCardSpeedDial = ({actions, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const speedDialRef = useRef(null);




    useEffect(() => {
        const handleClickOutside = (event) => {
            console.log("click")
            if (speedDialRef.current && !speedDialRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            window.addEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <SpeedDial
            ref={speedDialRef}
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute', bottom: 20, right: 20 }}
            open={isOpen}
            onClick={e => {
                e.preventDefault();
                setIsOpen((prev) => !prev); // הופך את המצב
                }
            }
            FabProps={{
                style: {
                    transform: 'scale(1.3)',
                    background: "linear-gradient(90deg, #33309F 50%, #8681F5 100%)"
                }
            }}
            icon={<SpeedDialIcon
                icon={<AddCardIcon />}
                openIcon={<CloseIcon />}
            />}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={
                        <Box
                            className="dir"
                            onClick={action.action}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: "5px",
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: action.color,
                                color: 'white',
                                height: "40px",
                                borderRadius: '25px',
                                padding: '5px 15px',
                                boxShadow: '0px 3px 5px rgba(0,0,0,0.3)',
                                minWidth: '70px', // מינימום רוחב לכל הכפתורים
                                whiteSpace: 'nowrap', // מניעת ירידת שורה
                                textAlign: 'center', // יישור הטקסט והאייקון
                                '&:hover': {
                                    backgroundColor: action.color,
                                    opacity: 0.8,
                                },
                            }}
                        >
                            {action.icon}
                            <Typography
                                sx={{
                                    fontSize: '0.9rem',
                                    color: 'white',
                                    textTransform: 'none',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {action.name}
                            </Typography>
                        </Box>
                    }
                    tooltipTitle=""
                />

            ))}
        </SpeedDial>
    )
}

export default React.memo(AddCardSpeedDial);
