import React, { useRef, useState, useEffect } from 'react';
import Split from 'react-split';
import "../../scss/VicaSplit.scss"

const VicaSplit = ({
                          direction = "vertical",
                          gutterSize = 10,
                          cursor = "row-resize",
                          minSize, maxSize,
                          style,
                          children,
                          onClick,
                          initialSizes = [50, 50],
                          onDragStart,
                          focusSection = -1,
                          onDragEnd
                      }) => {
    const gutterRef = useRef(null);

    const [splitSizes, setSplitSizes] = useState(initialSizes);
    const [splitClass, setSplitClass] = useState("");
    const [splitStateClass, setSplitStateClass] = useState("");

    // פונקציה לזיהוי התחלת מגע או לחיצה
    const handleStart = (event) => {
        gutterRef.current = {
            startX: event.touches ? event.touches[0].clientX : event.clientX,
            startY: event.touches ? event.touches[0].clientY : event.clientY,
            isDragging: false,
        };
    };

    useEffect(() => {
        switch(focusSection){
            case 0:
                setSplitStateClass("focus section_0");
                break;
            case 1:
                setSplitStateClass("focus section_1");
                break;
            case -1:
                setSplitStateClass("");
        }
    }, [focusSection])

    // פונקציה לזיהוי סיום מגע או לחיצה
    const handleEnd = (event) => {
        if (!gutterRef.current) return;

        const endX = event.changedTouches ? event.changedTouches[0].clientX : event.clientX;
        const endY = event.changedTouches ? event.changedTouches[0].clientY : event.clientY;
        const diffX = Math.abs(endX - gutterRef.current.startX);
        const diffY = Math.abs(endY - gutterRef.current.startY);

        // אם אין תזוזה משמעותית, זה קליק
        if (diffX < 5 && diffY < 5) {
            setTimeout(() => {
                setSplitSizes(prevSizes => {
                    if (prevSizes[1] < 1) {
                        setSplitClass("");
                        return [80, 20];
                    } else {
                        setSplitClass("minimized");
                        return [100, 0];
                    }
                });
            }, 10)

            onClick?.(event);

        }
    };

    // יצירת ה-gutter עם מאזיני אירועים מותאמים
    const gutter = (index, direction) => {
        const gutterElement = document.createElement('div');
        gutterElement.className = 'gutter';

        // מאזינים לאירועי עכבר ומגע
        gutterElement.addEventListener('mousedown', handleStart);
        gutterElement.addEventListener('mouseup', handleEnd);
        gutterElement.addEventListener('touchstart', handleStart);
        gutterElement.addEventListener('touchend', handleEnd);

        return gutterElement;
    };

    return (
        <Split
            direction={direction}
            sizes={splitSizes}
            gutter={gutter}
            cursor={cursor}
            minSize={minSize}
            maxSize={maxSize}
            className={`${splitClass} ${splitStateClass}`}
            style={style}
            gutterSize={gutterSize}
            onDragStart={e => {
                setSplitClass("");
                onDragStart?.(e);
            }}
            onDragEnd={newSizes => {
                console.log(newSizes);
                if(newSizes[1] < 15){
                    console.log("minimizing")
                    setSplitSizes([100, 0]);
                    setSplitClass("minimized");
                } else {
                    setSplitSizes(newSizes);
                }

                onDragEnd?.(newSizes);
            }}
        >
            {children}
        </Split>
    );
};

export default React.memo(VicaSplit);
